import { useEffect, RefObject } from 'react';

const useCarouselScroll = ({
  activeTabRef,
  containerRef,
}: {
  activeTabRef: RefObject<HTMLButtonElement>;
  containerRef: RefObject<HTMLDivElement>;
}) => {
  useEffect(() => {
    const itemElem = activeTabRef.current;
    const containerElem = containerRef.current;

    if (containerElem && itemElem) {
      const containerLeftPos = containerElem.scrollLeft;
      const containerWidth = containerElem.clientWidth;
      const itemWidth = itemElem.clientWidth;
      const itemLeftOffset = itemElem.offsetLeft;
      const itemLeftPos = itemLeftOffset - containerElem.offsetLeft - 2;
      const isOutOfBoundsRight =
        itemLeftPos + itemWidth > containerLeftPos + containerWidth;

      const isOutOfBoundsLeft = itemLeftPos < containerLeftPos;

      if (isOutOfBoundsRight) {
        /* eslint-disable-next-line */
        containerElem.scrollLeft = itemLeftPos + itemWidth;
      }

      if (isOutOfBoundsLeft) {
        /* eslint-disable-next-line */
        containerElem.scrollLeft = itemLeftPos; // -2 for margin and border
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabRef.current]);
};

export default useCarouselScroll;
