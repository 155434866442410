import { forwardRef, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TextColourProp } from '@ui-v2/types/props';
import { createTypography, mqMax, mqMin } from '@ui-v2/utils/styleUtils';
import LoadingSpinner from '../..//Loaders/LoadingSpinner';
import Box from '../../Box/Box';
import Text from '../../Text/Text';
import {
  InputIconLeft,
  InputIconRight,
  InputProps as SharedInputProps,
  InputState,
  StyledInput,
  StyledLoaderBox,
  StyledWrapper,
} from './SharedInputComponents';
import { getInputIconLeft, getInputIconRight, getInputState } from './utils';

export type InputProps = SharedInputProps;

export const labelColourMapper: Record<InputState, TextColourProp> = {
  default: 'text.subdued',
  error: 'text.critical',
  disabled: 'text.disabled',
};

const StyledLabel = styled('label')<{ isActive: boolean; isFocused: boolean }>(
  ({ isActive, isFocused, theme }) => [
    createTypography(theme.typography.body03),
    css`
      position: absolute;
      top: 7px;
      left: ${theme.spacings[12]}px;
      display: flex;
      color: ${theme.colours.text.subdued};
      opacity: 0;
      transform: translateY(-4px);
      transition:
        color 200ms,
        transform 200ms,
        opacity 200ms;

      ${mqMin[768]} {
        left: ${theme.spacings[16]}px;
      }
    `,
    isActive &&
      css`
        opacity: 1;
        transform: translateY(0);
      `,
    isFocused &&
      css`
        color: ${theme.colours.brand.accent};
      `,
  ],
);

const StyledFloatingInput = styled(StyledInput)<{
  isActive: boolean;
  isFocused: boolean;
}>(({ isActive, isFocused, theme }) => [
  css`
    outline: 1px solid ${theme.colours.border.default};
    padding-top: ${theme.spacings[16]}px;
    padding-bottom: ${theme.spacings[16]}px;
    transition:
      padding 200ms,
      outline-color 200ms;

    &:hover,
    &:focus {
      outline: 1px solid ${theme.colours.border.interactive};
    }

    ${mqMax[768]} {
      padding-top: ${theme.spacings[16]}px;
      padding-bottom: ${theme.spacings[16]}px;
    }
  `,
  isActive &&
    css`
      padding-top: 24px;
      padding-bottom: 8px;

      ${mqMax[768]} {
        padding-top: 24px;
        padding-bottom: 8px;
      }
    `,
  isFocused &&
    css`
      outline: 1px solid ${theme.colours.brand.accent};

      &:hover,
      &:focus {
        outline: 1px solid ${theme.colours.brand.accent};
      }
    `,
]);

/**
 * A floating label input that has two active states
 * Focus: The input is in focus mode and shows an orange border
 * Active: The input is active and has been condensed to show the label
 * in the upper left corner
 */
// eslint-disable-next-line react/display-name
export const InputFloatingLabel = forwardRef<HTMLInputElement, InputProps>(
  (props, forwardedRef) => {
    const {
      disabled,
      errorMessage,
      iconLeft,
      iconRight,
      id,
      isLoading,
      label,
      mb,
      mt,
      name,
      overriddenActiveState,
      overriddenFocusState,
      placeholder,
      ...rest
    } = props;

    const [isActive, setIsActive] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    // Handle updates to an overridden focus state
    useEffect(() => {
      if (overriddenFocusState !== undefined) {
        setIsFocused(overriddenFocusState);
      }
    }, [overriddenFocusState]);

    // Handle updates to an overridden active state
    useEffect(() => {
      if (overriddenActiveState !== undefined) {
        setIsActive(overriddenActiveState);
      }
    }, [overriddenActiveState]);

    // Respond to outside changes to the value
    useEffect(() => {
      if (overriddenActiveState !== undefined) {
        return;
      }

      if (props.value && !isActive) {
        setIsActive(true);
      } else if (!props.value && isActive && !isFocused) {
        setIsActive(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    const state = getInputState({
      disabled,
      errorMessage,
    });

    const renderedIconRight = getInputIconRight({
      iconRight,
      state,
    });

    const renderedIconLeft = getInputIconLeft({
      iconLeft,
    });

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      if (overriddenFocusState === undefined) {
        setIsActive(true);
        setIsFocused(true);
      }
      props.onFocus?.(e);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      if (!e.target.value && overriddenFocusState === undefined) {
        setIsActive(false);
      }

      if (overriddenFocusState === undefined) {
        setIsFocused(false);
      }
      props.onBlur?.(e);
    };

    return (
      <StyledWrapper mb={mb} mt={mt}>
        <Box position="relative">
          <StyledLabel
            htmlFor={id}
            id={`${name}-label`}
            isActive={isActive}
            isFocused={isFocused}
          >
            {label}
          </StyledLabel>
          {renderedIconLeft && (
            <InputIconLeft
              isFloatingVariantActive={isActive}
              state={state}
              type={renderedIconLeft}
              variant="floating-label"
            />
          )}
          <StyledFloatingInput
            aria-label={label}
            id={id}
            {...rest}
            disabled={disabled}
            hasIconLeft={Boolean(renderedIconLeft)}
            hasIconRight={Boolean(renderedIconRight)}
            isActive={isActive}
            isFocused={isFocused}
            name={name}
            onBlur={handleBlur}
            onFocus={handleFocus}
            placeholder={isActive ? '' : placeholder}
            ref={forwardedRef}
            state={state}
          />
          {!isLoading && renderedIconRight && (
            <InputIconRight
              isFloatingVariantActive={isActive}
              state={state}
              type={renderedIconRight}
              variant="floating-label"
            />
          )}
          {isLoading && (
            <StyledLoaderBox
              alignItems="center"
              bottom={0}
              display="flex"
              justifyContent="center"
              position="absolute"
              right={16}
              top={0}
            >
              <LoadingSpinner colour="icons.subdued" diameter={20} />
            </StyledLoaderBox>
          )}
        </Box>
        {state === 'error' && (
          <Text as="div" colour="text.critical" variant="body-2">
            {errorMessage}
          </Text>
        )}
      </StyledWrapper>
    );
  },
);

export default InputFloatingLabel;
