import { breakpoints } from '@ui-v2/theme/layout';
import useWindowWidth from './useWindowWidth';

export const useIsHandheld = () => {
  const width = useWindowWidth({
    ssr: true,
  });

  return {
    width,
    isHandheld: width ? width < breakpoints[2] : null,
  };
};

export default useIsHandheld;
