import { IconConfigFragment } from '@codegen/cmsUtils';
import { BadgeVariant } from '@ui-v2/core/Badge/Badge';
import CoreNotification, { Props } from '@ui-v2/core/Notification/Notification';

export const getNotificationIcon = (
  variant: BadgeVariant,
): keyof IconConfigFragment => `${variant}Icon`;

const Notification = ({ icon, variant = 'information', ...props }: Props) => {
  const iconIdentifier = getNotificationIcon(variant);
  const resolvedIcon = icon ? icon : iconIdentifier;

  return <CoreNotification icon={resolvedIcon} variant={variant} {...props} />;
};

export default Notification;
